import EmailValidator from 'email-validator';
import swal from 'sweetalert';

export const submitSignUp = async (event) => {
  event.preventDefault();
  const email = event.target.emailAddress.value;

  // belt and braces email validation check
  if (!EmailValidator.validate(email)) {
    console.warn("Invalid email submitted");
    return;
  }

  const response = await fetch('/api/newsletterSignup', {
    body: JSON.stringify({
      email: email,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  // log failed response - currently just to console
  if (!response.ok) {
    console.error(`ERROR: ${response.status}`);
  }

  const result = await response.json();
  swal({
    title: "Thanks for subscribing. We'll be in touch soon with our CTO Labs Newsletter.",
    text: result.email,
    icon: "success",
    button: "close"
  });
};